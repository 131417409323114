function initApi(googleApiUrl) {

  if (!googleApiUrl) {
    console.info('No Google Maps API url has been found, please check if API key is filled in.')
    return;
  }

  // Append script
  const jsFile = document.createElement('script')
  jsFile.type = 'text/javascript'
  jsFile.src = googleApiUrl
  jsFile.setAttribute('async', '')
  jsFile.setAttribute('defer', '')
  document.getElementsByTagName('head')[0].appendChild(jsFile)

  // Google authFailure callback
  window.gm_authFailure = function () {
    throw new Error('Google maps authentication failure')
  }
}

export default initApi
